"use client";

import { SVGProps } from "react";
import Link from "next/link";

export const BrandIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <Link href="/">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g>
          <rect fill="#fff" width="24" height="24" />
          <g fill="#000">
            <path d="m16.83,5.71c-.56.82-1.1,1.62-1.65,2.43-.22-.12-.43-.23-.63-.34-2.46-1.26-6.32.01-6.8,3.57-.35,2.61,1.53,5.31,4.6,5.23,1.94-.05,3.44-.97,4.36-2.73,0-.02.02-.04.03-.05,0,0,.01-.01.04-.04.87.4,1.75.8,2.66,1.22-.05.11-.09.22-.15.32-1.01,1.9-2.52,3.23-4.58,3.88-1.47.47-2.97.6-4.48.22-2.18-.56-3.91-1.74-4.94-3.79-2.19-4.38-.05-8.87,3.76-10.53,1.38-.6,2.82-.86,4.32-.67,1.18.15,2.29.53,3.3,1.19.04.03.09.06.14.1Z" />
            <path d="m19.56,10.38c.07.84-.75,1.39-1.47,1.4-.87.02-1.46-.56-1.47-1.45-.01-.85.62-1.42,1.58-1.43.81-.01,1.35.57,1.37,1.48Z" />
          </g>
        </g>
      </svg>
    </Link>
  );
};
